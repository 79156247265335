import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./portfolio.css";
import Header from "./header";
import Footer from "./footer";
import Blkmain from "./svg/blckchnmain.svg";
import Developmentsvg from "./svg/development.svg";
import Newtab from "./svg/newtab.svg";
import Loading from "./loader2";

const About = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleLogout = (e) => {};
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <Header />
          <Loading />
        </div>
      ) : (
        <>
          <div className="about_container">
            <section className="about_banner">
              <div className="about_center_name">
                <h1 className="about_name cssanimation sequence fadeInTop">
                  Hallo, Ich bin Sridhar
                </h1>
                <div className="tudiv">
                  <h1 className="about_description cssanimation sequence fadeInBottom">
                    SENIOR FRONTEND-WEBENTWICKLER
                  </h1>
                </div>
                <a class="ca3-scroll-down-link ca3-scroll-down-arrow"></a>
              </div>

              <div className="about_center_namemq">
                <h1 className="about_namemq cssanimation sequence fadeInTop">
                  Hallo, Ich bin
                </h1>

                <h1 className="about_namemq cssanimation sequence fadeInTop">
                  Sridhar
                </h1>

                <h1 className="about_descriptionmq cssanimation sequence fadeInBottom">
                  SENIOR FRONTEND
                </h1>
                <h1 className="about_descriptionmq cssanimation sequence fadeInBottom">
                  {" "}
                  WEBENTWICKLER
                </h1>
                <a class="ca3-scroll-down-link ca3-scroll-down-arrow"></a>
              </div>
            </section>
            <section>
              <div className="about_container_memq">
                <h1 id="title_ani" className="intro_con_titlemq">
                  EIN BISSCHEN ÜBER MICH
                </h1>
                <div className="about_pic_containernq">
                  <img src="/images/sridhar-3.jpeg" className="pic_memq" />
                </div>
                <div className="about_para_containermq">
                  <div className="intro_para_containermq">
                    <h2 className="into_para_1mq">
                      Hallo! Ich bin Sridhar, ein leidenschaftlicher
                      Softwareentwickler, der in Leipzig, Deutschland, lebt. Ich
                      habe mich auf Frontend-Webentwicklung spezialisiert und
                      verfüge über mehr als 3 Jahre Erfahrung in der Erstellung
                      von responsiven und ansprechenden Benutzeroberflächen.
                      Meine Expertise umfasst die Lösung komplexer technischer
                      Probleme, die Durchführung von gründlicher Recherche und
                      die Förderung von Innovation in verschiedenen Projekten.
                      Ob es darum geht, Benutzeroberflächendesigns für nahtlose
                      Interaktionen zu optimieren oder responsive Layouts für
                      unterschiedliche Geräte zu implementieren – ich gehe
                      Herausforderungen mit Begeisterung an. Neben dem
                      Programmieren spiele ich gerne Badminton, Tischtennis und
                      koche mit Freude. Ich bin immer offen für Zusammenarbeit,
                      Lernen und einen wertvollen Beitrag zu leisten. <br></br>
                      Zögern Sie nicht, mich zu kontaktieren – ich freue mich
                      auf den Austausch! &nbsp;
                      <lnkd>
                        <a
                          href="https://www.linkedin.com/in/sridharsakthivel"
                          className="lnnkd"
                          target="_blank"
                          rel="noreferrer"
                        >
                          LinkedIn
                          <img
                            src={Newtab}
                            alt="view certificate"
                            className="newtab"
                          />
                        </a>
                      </lnkd>
                    </h2>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="Section_what_I_Do">
                <div className="wid_title">
                  <h1 id="title_ani1">WAS ICH MACHE</h1>
                </div>

                <div className="wid_des">
                  <div className="wid_des_webdev">
                    <div className="bg_circle1">
                      <img
                        src={Developmentsvg}
                        alt="development"
                        className="codesvgpng"
                      />
                    </div>

                    <h1 id="title_ani2" className="wid_title_name">
                      FRONTEND WEBENTWICKLUNG
                    </h1>

                    <p className="wid_para_des">
                      Als Webentwickler habe ich eine solide Grundlage in der
                      Front-End-Entwicklungstechnologie, mit besonderer
                      Expertise in der Nutzung der React-Bibliothek zur
                      Erstellung von responsiven und visuell ansprechenden
                      Benutzeroberflächen. Meine Kenntnisse umfassen das Design
                      und die Implementierung von wiederverwendbaren und
                      wartbaren Komponenten, die die Effizienz und
                      Skalierbarkeit von Anwendungen erheblich verbessern. Ich
                      bin versiert im Einsatz von Redux, Context API und anderen
                      State-Management-Tools, um den Datenfluss zu optimieren
                      und die Gesamtleistung der Anwendung zu verbessern.
                      Darüber hinaus lege ich großen Wert auf Barrierefreiheit
                      und stelle sicher, dass meine Designs die
                      unterschiedlichen Bedürfnisse der Nutzer berücksichtigen.
                      Zusammengefasst zeichnen sich meine
                      Webentwicklungsfähigkeiten durch einen ganzheitlichen
                      Ansatz aus, der die Lücke zwischen Benutzererfahrung und
                      technischer Ausführung effektiv überbrückt.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Header />
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default About;
