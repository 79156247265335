import React, { useState, useEffect } from "react";
import "./portfolio.css";
import HeaderStatic from "./headerStatic";
import Exp_accordion from "./exp_accordion";
import Footer from "./footer";
import Loading from "./loader";

const Experience = () => {
  const [isloading, setisloading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setisloading(true);
    window.scrollTo(0, 0);
    setisloading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <HeaderStatic message="exp" />
          <Loading />
        </div>
      ) : (
        <div className="exp_container">
          {" "}
          <HeaderStatic message="exp" />
          {isloading == false ? (
            <div className="exp_Accordion_123">
              <h1 className="exp_title cssanimation sequence fadeInTop">
                BERUFSERFAHRUNG
              </h1>
              <Exp_accordion />
            </div>
          ) : (
            ""
          )}
          <Footer />
        </div>
      )}
    </>
  );
};

export default Experience;
