import React, { useState, useEffect } from "react";
import "./portfolio.css";
import HeaderStatic from "./headerStatic";
import Exp_accordion from "./exp_accordion";
import Footer from "./footer";
import Send from "./svg/send.svg";
import Location from "./svg/location.svg";
import Whatsapp from "./svg/whatsapp.svg";
import Linkedin from "./svg/linkedin.svg";
import Email from "./svg/email.svg";
import Newtabc from "./svg/newtabc.svg";
import Loading from "./loader";

const Contact = () => {
  const [isloading, setisloading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <HeaderStatic message="contact" />
          <Loading />
        </div>
      ) : (
        <div className="cnt_container">
          <HeaderStatic message="contact" />

          {isloading == false ? (
            <div className="contact_container">
              <img
                src={Send}
                alt="Contact"
                className="newtab1 cssanimation sequence fadeInBottom"
              />
              <h1 className="cnt_title cssanimation sequence fadeInTop">
                NIMM KONTAKT AUF
              </h1>
              <div className="contactsdiv">
                <div className="contact_location cmnfont">
                  <div className="contact_circle1">
                    <img
                      src={Location}
                      alt="Location"
                      className="contact_icons"
                    />
                  </div>
                  <h1 className="contact_title">Leipzig, Deutschland</h1>
                </div>
                <div className="contact_linkedin cmnfont">
                  <a
                    href="https://www.linkedin.com/in/sridharsakthivel"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <div className="contact_circle3">
                      <img
                        src={Linkedin}
                        alt="Telegram"
                        className="contact_icons"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/sridharsakthivel"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <h1 className="contact_title">
                      &nbsp;&nbsp;&nbsp;&nbsp;LinkedIn
                      <img
                        src={Newtabc}
                        alt="newtab"
                        className="contact_icons_newtab"
                      />
                    </h1>
                  </a>
                </div>
                <div className="contact_whatsapp cmnfont">
                  <a
                    a
                    href="https://wa.me/4915510822305?text=Hi, Sridhar Sakthivel"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <div className="contact_circle2">
                      <img
                        src={Whatsapp}
                        alt="Whatsapp"
                        className="contact_icons"
                      />
                    </div>
                  </a>
                  <a
                    href="https://wa.me/4915510822305?text=Hi, Sridhar Sakthivel"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <h1 className="contact_title">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whatsapp
                      <img
                        src={Newtabc}
                        alt="newtab"
                        className="contact_icons_newtab"
                      />
                    </h1>
                  </a>
                </div>
                <div className="emaildiv cmnfont">
                  <a
                    href="mailto:sridharsakthivel24@gmail.com"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <div className="contact_circle4">
                      <img
                        src={Email}
                        alt="Telegram"
                        className="contact_icons"
                      />
                    </div>{" "}
                  </a>
                  <a
                    href="mailto:sridharsakthivel24@gmail.com"
                    target="_blank"
                    className="cmna"
                    rel="noreferrer"
                  >
                    <h1 className="contact_title">
                      &nbsp;&nbsp;&nbsp;Email
                      <img
                        src={Newtabc}
                        alt="newtab"
                        className="contact_icons_newtab"
                      />
                    </h1>
                  </a>
                </div>
              </div>
              <div className="emaildivision">
                <h1>
                  Egal, ob du eine Idee für ein Projekt hast oder einfach nur
                  plaudern möchtest,
                  <br></br> zögere nicht, mir eine E-Mail zu schicken!
                </h1>
                <a
                  href="mailto:sridharsakthivel24@gmail.com"
                  target="_blank"
                  className="hello_button cmna"
                  rel="noreferrer"
                >
                  &nbsp;&nbsp;Sag Hallo!&nbsp;&nbsp;
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
          <Footer />
        </div>
      )}
    </>
  );
};

export default Contact;
