import React, { useState } from "react";

import "./portfolio.css";

import Plusicon from "./svg/plus-solid.svg";

import Minusicon from "./svg/minus-solid.svg";

import AliceCarousell from "./alice-carousel";
import Uparrow from "./svg/up-arrow.svg";
import Downarrow from "./svg/down-arrow.svg";

const Exp_Accordion = () => {
  const [isActive, setIsActive] = useState(false);

  function onclick1() {
    setIsActive(!isActive);
  }

  var df = new Date("20 May 2021 0:00 UTC");
  var dt = new Date("29 Oct 2024 0:00 UTC");
  var startMonth = df.getFullYear() * 12 + df.getMonth();
  var endMonth = dt.getFullYear() * 12 + dt.getMonth();
  var monthInterval = endMonth - startMonth;
  var yearsOfExperience = Math.floor(monthInterval / 12);
  var TotalExperience = monthInterval / 12;

  return (
    <>
      <div className="exp_accordion-item">
        <div className="exp_accordion-title">
          <div className="exp_at_title">Mai 2021 - Okt 2024</div>
        </div>

        <div className="exp_accordion-content2 fwa">
          <div className="exp_accdesbox1">
            <div className="exp_frontend">
              <img
                className="TCS_logo"
                src="/images/TCS_LOGO.png"
                alt="Tata Consultancy Services"
              />
            </div>

            <div className="exp_frontenddes">
              <h6 className="exp_ss">TATA CONSULTANCY SERVICES</h6>
              <h2>
                Rolle:<span>&nbsp;Web Entwickler</span>
              </h2>
              <h2>
                Bezeichnung:<span>&nbsp; Systemingenieur</span>
              </h2>
              <h2>
                Erfahrung:
                <span>&nbsp;{TotalExperience.toFixed(2)} Jahre</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="exp_accordion-item awa1">
        <div
          className={
            isActive ? "exp_accordion-title1act" : "exp_accordion-title1"
          }
          onClick={onclick1}
        >
          <div className="exp_at_title1">Auszeichnungen und Erfolge</div>

          <div className="plusminus">
            {isActive ? (
              <img src={Uparrow} alt="Plusicon" className="plusminus1" />
            ) : (
              <img src={Downarrow} alt="Minusicon" className="plusminus1" />
            )}
          </div>
        </div>
        {isActive && (
          <div className="exp_accordion-content2">
            <div className="awardsclass">
              <div className="alice_caro">
                <AliceCarousell />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Exp_Accordion;
