import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./portfolio.css";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = (e) => {};
  window.addEventListener("scroll", function () {
    let header = document.querySelector("header");
    header.classList.toggle("sticky", window.scrollY > 0);
  });

  useEffect(() => {
    let hamburger = document.querySelector(".hamburger");
    hamburger.onclick = function () {
      let navbar1 = document.querySelector(".header_ul");
      navbar1.classList.toggle("active");
    };
  }, []);

  function funcabout() {
    navigate("/");
  }

  function funcskills() {
    navigate("/Skills");
  }
  function funcexp() {
    navigate("/Experience");
  }

  function funccontact() {
    navigate("/Contact");
  }

  function funcresume() {
    navigate("/Resume");
  }

  return (
    <div className="header_containerq" id="header_container1">
      <header className="header_logoimageq">
        <div className="header_inameq">
          <a onClick={funcabout}>
            <img src="images/s_logo1.png" className="header_logoimgq" />
          </a>
          <a onClick={funcabout} className="header_logoq">
            SRIDHAR SAKTHIVEL
          </a>
        </div>

        <div className="hamburger">
          {" "}
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <ul className="header_ul">
          <li>
            <a onClick={funcabout} className="menuactive">
              ÜBER MICH
            </a>
          </li>

          <li>
            <a onClick={funcskills}>FÄHIGKEITEN</a>
          </li>

          <li>
            <a onClick={funcexp}>ERFAHRUNG</a>
          </li>
          <li>
            <a onClick={funccontact}>KONTAKT</a>
          </li>

          <li>
            <a onClick={funcresume}>LEBENSLAUF</a>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default Header;
