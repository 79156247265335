import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./portfolio.css";

const HeaderStatic = (props) => {
  const navigate = useNavigate();
  const handleLogout = (e) => {};
  window.addEventListener("scroll", function () {
    let header = document.querySelector("header");
    header.classList.toggle("sticky2", window.scrollY > 0);
  });

  useEffect(() => {
    if (props.message == "Skills") {
      let navbar1 = document.querySelector(".funcskills");
      navbar1.classList += " menuactive";
    } else if (props.message == "exp") {
      let navbar1 = document.querySelector(".funcskills");
      navbar1.classList -= " menuactive";
      let navbar2 = document.querySelector(".funcexp");
      navbar2.classList += " menuactive";
    } else if (props.message == "contact") {
      let navbar1 = document.querySelector(".funcskills");
      navbar1.classList -= " menuactive";
      let navbar2 = document.querySelector(".funccontact");
      navbar2.classList += " menuactive";
    } else if (props.message == "resume") {
      let navbar1 = document.querySelector(".funcskills");
      navbar1.classList -= " menuactive";
      let navbar2 = document.querySelector(".funcresume");
      navbar2.classList += " menuactive";
    }
    let hamburger = document.querySelector(".hamburger");
    hamburger.onclick = function () {
      let navbar1 = document.querySelector(".header_ul");
      navbar1.classList.toggle("active");
    };
  }, []);

  function funcabout() {
    navigate("/");
  }

  function funcskills() {
    navigate("/Skills");
  }

  function funcexp() {
    navigate("/Experience");
  }

  function funccontact() {
    navigate("/Contact");
  }

  function funcresume() {
    navigate("/Resume");
  }

  return (
    <div className="header_containerq" id="header_container1">
      <header className="header_logoimage2">
        <div className="header_inameq">
          <a onClick={funcabout}>
            <img src="images/s_logo1.png" className="header_logoimgq" />
          </a>
          <a onClick={funcabout} className="header_logoq">
            SRIDHAR SAKTHIVEL
          </a>
        </div>

        <div className="hamburger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <ul className="header_ul">
          <li>
            <a onClick={funcabout} className="funcabout">
              ÜBER MICH
            </a>
          </li>

          <li>
            <a onClick={funcskills} className="funcskills">
              FÄHIGKEITEN
            </a>
          </li>

          <li>
            <a onClick={funcexp} className="funcexp">
              ERFAHRUNG
            </a>
          </li>
          <li>
            <a onClick={funccontact} className="funccontact">
              KONTAKT
            </a>
          </li>

          <li>
            <a onClick={funcresume} className="funcresume">
              LEBENSLAUF
            </a>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default HeaderStatic;
